import React, { ReactElement } from "react";
import colors from "@styles/variables/creditcardprocessorVariables.module.scss";

import FeatureAndFunctionalityIcon from "../icons/featureAndFunctionality";
import CustomerSupportIcon from "../icons/customerSupportIcon";
import EasyOfUseIcon from "../icons/easyOfUse";
import FeatureList from "@components/shared/featureList";

const whyUsList = [
    {
        title: "Easy to Use",
        description:
            "We carefully selected companies that offer simple credit card processors to set up and use, including effortless importation of data and invoices and intuitive report viewing.",
        icon: <EasyOfUseIcon />,
    },
    {
        title: "Features & Benefits",
        description:
            "We considered critical features and tools in our comparisons, such as seamless software integrations, efficient data exports, streamlined invoicing, and insightful reporting dashboards.",
        icon: <FeatureAndFunctionalityIcon />,
    },
    {
        title: "Customer Support",
        description:
            "The companies we evaluated provide diverse customer support options like phone support, live chat, informative blogs, and comprehensive FAQs.",
        icon: <CustomerSupportIcon />,
    },
];

export default function WhyUs(): ReactElement {
    const scrollToTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };
    return (
        <>
            <FeatureList
                featureList={whyUsList}
                mainTitle="Why Choose CreditCardProcessingRates.com?"
                classes={{
                    mainTitleClasses: "text-center text-xl  lg:text-2xl mb-14",
                    innerSectionClasses: "lg:flex flex-wrap justify-center",
                    oneBoxClasses: "text-center mb-12 lg:mb-20 w-full lg:w-1/3",
                    iconClasses: "children:mx-auto",
                    titleClasses: "font-medium text-lg lg:text-xl mt-6",
                    descriptionClasses:
                        "font-light text-sm text-center w-4/5 mx-auto mt-2",
                }}
                colors={{
                    mainTitleColor: "#4558A7",
                    descriptionColor: colors.whyUsBriefColor,
                }}
            />
            <button
                onClick={() => scrollToTop()}
                style={{ backgroundColor: "rgb(69, 88, 167)" }}
                className={` text-white  py-4 sm:px-11 px-4 sm:text-lg font-semibold rounded-lg block mx-auto mb-20 hover:-translate-y-2 hover:transition-all transition-all`}
            >
                Get My Free Credit Card Processor Quote Now
            </button>
        </>
    );
}
